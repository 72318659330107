export function initlanding() {
    let index = document.getElementById("landing-index");
    if (index) {
        indexObserver();
        indexScroll();
    }
}

function indexObserver() {

    let parent = document.getElementById("landing-index");

    let menu = {
        list: parent.querySelectorAll("li"),
        active: parent.querySelector("li.active"),
    };

    let landing = document.getElementById("landing");
    let cards = {
        list: landing.querySelectorAll("section.card"),
        active: landing.querySelector("section.card.active")
    }

    if (cards.list) {
        let observer;

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.5],
        };

        const indexintersect = (entries) =>
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // GET INDEX
                    let index = parseInt(entry.target.dataset.index);

                    // ADD TRANSITION
                    cards.active.classList.remove('active');
                    entry.target.classList.add('active');
                    cards.active = entry.target;

                    // REMOVE ACTIVE
                    menu.active.classList.remove("active");

                    //SET ACTIVE
                    menu.list[index].classList.add("active");
                    menu.active = menu.list[index];
                }
            });

        observer = new IntersectionObserver(indexintersect, options);
        cards.list.forEach((card) => {
            observer.observe(card);
        });
    }
}

function indexScroll() {
    let parent = document.getElementById("landing-index");

    let menu = {
        list: parent.querySelectorAll("li"),
        active: parent.querySelector("li.active"),
    };

    let landing = document.getElementById("landing");
    let cards = landing.querySelectorAll("section.card");

    menu.list.forEach((li) => {
        li.addEventListener("click", () => {
            let index = parseInt(li.dataset.index);
            let top = cards[index].getBoundingClientRect().top;
            window.scrollTo({
                top: top + window.scrollY,
                behavior: "smooth",
            });
        });
    });
}
