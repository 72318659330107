export function initindex() {
    let index = document.getElementById("index");
    if (index) {
        initFilter();
        initView();
        initList();
    }
}

function initFilter() {
    let parent = document.getElementById("index-kategorien");
    let list = parent.querySelectorAll("li");

    // GET ATTRIBUTES
    let attributes = [];

    list.forEach((li) => {
        attributes.push(li.dataset.kategorie);
    });

    // CLICK EVENT
    list.forEach((li) => {
        li.addEventListener("click", (e) => {
            attributes.forEach((a) => {
                document.body.removeAttribute(a);
            });
            document.body.removeAttribute("filter");

            if (!e.target.classList.contains("no-filter")) {
                let slug = e.target.dataset.kategorie;
                document.body.setAttribute("filter", "");
                document.body.setAttribute(slug, "");
            }

            parent.querySelector(".active").classList.remove("active");
            e.target.classList.add("active");
        });
    });
}

function initView() {
    let parent = document.getElementById("index-view");
    let list = parent.querySelectorAll("li");

    // CLICK EVENT
    list.forEach((li) => {
        li.addEventListener("click", (e) => {
            let view = e.target.dataset.view;
            if (view === "list") {
                document.body.setAttribute("list", "");
            } else {
                document.body.removeAttribute("list");
            }

            parent.querySelector(".active").classList.remove("active");
            e.target.classList.add("active");
        });
    });
}

function initList() {
    let main = document.getElementById("projekte");
    let projekte = main.querySelectorAll(".grid");

    let parent = document.getElementById("list-filter");
    let list = parent.querySelectorAll("section");

    list.forEach((l) => {
        l.addEventListener("click", (e) => {
            // GET CATEGORY
            let sort = e.target.dataset.list;

            // STRUCTURE CONTENT
            let content = [];
            projekte.forEach((projekt) => {
                let p = {
                    el: projekt,
                    value: parseInt(projekt.querySelector(`section.projekt-${sort}`).innerText),
                    name: projekt.querySelector(`section.projekt-${sort}`).innerText
                };
                
                content.push(p);
            });

            // SORT CONTENT
            if (sort === "jahr") {
                content.sort((a, b) => b.value - a.value);
            } else {
                content.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            }

            // ORDER ELEMENTS
            content.forEach((c, index) => {
                c.el.style.order = index;
            });

            // UPDATE BUTTONS
            parent.querySelector(".active").classList.remove("active");
            e.target.classList.add("active");
        });
    });
}
