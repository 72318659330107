export function initmenu() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");
    if (open && menu) {
        menuOpen();
        menuClose();
    }

    let invert = document.getElementById("menu-invert");
    if (invert) {
        secret();
    }
}

function menuOpen() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");
    let blur = document.getElementById("blur");

    open.addEventListener("click", () => {
        menu.classList.remove("inactive");
        blur.classList.remove("inactive");
    });
}

function menuClose() {
    let menu = document.getElementById("main");
    let blur = document.getElementById("blur");
    menu.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) return;
        menu.classList.add("inactive");
        blur.classList.add("inactive");
    });

    let list = menu.querySelector("li.gap");
    list.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) return;
        menu.classList.add("inactive");
        blur.classList.add("inactive");
    });
}

function secret() {
    let invert = document.getElementById("menu-invert");
    let dot = document.getElementById("logo-dot");
    if (invert && dot) {
        invert.addEventListener("click", openSecret);
    }
    function openSecret() {
        invert.removeEventListener("click", openSecret);
        let time = 450;

        document.body.setAttribute("invert-transition", "");
        document.body.setAttribute("invert-frame-1", "");

        let vw = window.innerWidth;
        let vh = window.innerHeight;
        let b = dot.getBoundingClientRect();

        let s = ((vw * Math.PI) / b.width) * 100;
        dot.style.transform = `scale(${s}%)`;

        let ol = (b.left / vw) * Math.PI * 100;
        let ot = (b.top / vh) * 100;
        dot.style.transformOrigin = `${ol}% ${ot}%`;

        setTimeout(() => {
            document.body.setAttribute("invert-frame-2", "");
            document.body.setAttribute("invert", "");

            setTimeout(() => {
                dot.style.transform = `scale(${100}%)`;
                document.body.removeAttribute("invert-frame-1");
                document.body.removeAttribute("invert-frame-2");

                invert.addEventListener("click", closeSecret);

            }, time * 2);
        }, time);
    }
    function closeSecret() {
        invert.removeEventListener("click", closeSecret);
        document.body.removeAttribute("invert");
        invert.addEventListener("click", openSecret);

    }
}
