// IMAGES

import { initlazy } from "./js/images";

// SECTIONS
import { initlanding } from "./js/navigation/landing";
import { initmenu } from "./js/navigation/menu";
import { initindex } from "./js/navigation";
import { initprojekt } from "./js/navigation/projekt";
import { initinformation } from "./js/navigation/information";

// INIT

init();

function init() {

    document.body.setAttribute('js', '');

    initlazy();

    initmenu();
    initlanding();
    initindex();
    initprojekt();
    initinformation();
}
