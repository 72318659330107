import LazyLoad from "vanilla-lazyload";

export function initlazy() {

    
    let threshold = window.innerHeight * 1.5;

    window.lazy = new LazyLoad({
        threshold: threshold,
        unobserve_entered: true
    });

}
